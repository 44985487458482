import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {pageName, Loader} from 'shared/helpers';
import {useConfirmationDialog} from 'shared';
import {useNavigate, useLocation} from 'react-router-dom';
import {cloneDeep, isEqual} from 'lodash';
import useUI from 'shared/useUI';
import {isBenchtopPage, isHardwarePage} from 'shared/helpers/routeUtils';
import {useAppContext} from 'contexts';
import {useLazyGetJobQuery} from 'components/customer/Job/store/jobApi';
import {produce} from 'immer';

export const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

export const JobProvider = ({children, jobId, roomId}) => {
    const {setIsLeftDashboardButtonVisible, userProfile} = useAppContext();
    const {
        setIsCartToggleVisible,
        restoreLeftSidebarBehaviour,
        restoreRightSidebarBehaviour,
    } = useUI();
    const {showDialog, dialog, hideDialog} = useConfirmationDialog();
    const navigate = useNavigate();
    const location = useLocation();

    const [isJobProcessing, setIsJobProcessing] = useState(true);
    const [loader, setLoader] = useState(false);
    const [forceRefresh, refresh] = useState({});
    const [room, setRoom] = useState({});
    const [isPageDirty, setIsPageDirty] = useState([]);

    const [job, setJob] = useState({});

    const [getJobDetails, {data: jobData, isLoading, isFetching, error}] =
        useLazyGetJobQuery();

    useEffect(() => {
        if (location.state != null && location.state.hasOwnProperty('room')) {
            setRoom(location.state.room);
        }
    }, [location.state]);

    const expiredJobPrice = useMemo(() => {
        return job.priceExpire;
    }, [job]);

    useEffect(() => {
        if (job && job.rooms && job.rooms.length) {
            let roomIndex;
            const currentRoom = job.rooms.find((room_, index) => {
                if (room.id == room_.id) {
                    roomIndex = index;
                    return true;
                }

                return false;
            });

            if (
                currentRoom &&
                typeof roomIndex !== 'undefined' &&
                !isEqual(currentRoom, room)
            ) {
                setJob((job) => {
                    const rooms = cloneDeep(job.rooms);
                    rooms[roomIndex] = room;

                    return {
                        ...job,
                        ...{
                            rooms,
                        },
                    };
                });
            }
        }
    }, [room]);

    useEffect(() => {
        // separating concerns for side bar
        if (!forceRefresh.hasOwnProperty('toggleSidebar')) {
            const page = pageName(location.pathname);
            if (page === 'job' && jobId) {
                setIsLeftDashboardButtonVisible(false);
                setIsCartToggleVisible(true);
                restoreLeftSidebarBehaviour(null);
                restoreRightSidebarBehaviour(null);
            } else {
                setIsLeftDashboardButtonVisible(false);
            }
        }
    }, [forceRefresh, jobId]);

    useEffect(() => {
        // separating concerns - effect to fetch new job details when forceRefresh change or jobId change
        setIsJobProcessing(true);
        if (jobId && !isNaN(jobId)) {
            getJobDetails({
                jobId: parseInt(jobId) - 10000,
                timestamp: Date.now(),
            });
        }
    }, [forceRefresh, jobId]);

    useEffect(() => {
        // separating concerns - effect to handle loading state only
        setLoader(isLoading || isFetching);
    }, [isLoading, isFetching]);

    useEffect(() => {
        const setNewRoom =
            forceRefresh.hasOwnProperty('setNewRoom') &&
            forceRefresh.setNewRoom;
        const persistRoom =
            forceRefresh.hasOwnProperty('persistRoom') &&
            forceRefresh.persistRoom;
        const currentRoom = roomId
            ? roomId
            : persistRoom
            ? persistRoom
            : room
            ? room.id
            : false;
        if (jobData && !error) {
            setIsJobProcessing(jobData.status == 0);
            let roomDetails;
            if (jobData.rooms.length) {
                roomDetails = jobData.rooms.find(
                    (room) => room.id == currentRoom
                );

                if (typeof roomDetails === 'undefined') {
                    let index = 0;
                    if (setNewRoom) {
                        index = jobData.rooms.length - 1;
                    }
                    // not an issue here as index is not a user input value
                    // eslint-disable-next-line security/detect-object-injection
                    roomDetails = jobData.rooms[index];
                }
            } else {
                setRoom({});
                if (
                    !isHardwarePage(location.pathname) &&
                    !isBenchtopPage(location.pathname)
                ) {
                    navigate(`/v2/job/${jobData.displayId}/dashboard`);
                }
            }

            setJob(cloneDeep(jobData));
            setRoom(
                typeof roomDetails === 'undefined' ? {} : cloneDeep(roomDetails)
            );
        } else if (error) {
            setJob({error});
        }
    }, [jobData, forceRefresh, error]);

    const actionButtonsEnabled = useMemo(() => {
        const enableButton =
            isJobProcessing && userProfile?.inActiveManufacturer == 0;
        const expiredJob = !userProfile.show_pricing ? false : expiredJobPrice;

        return enableButton && !expiredJob;
    }, [isJobProcessing, userProfile, expiredJobPrice]);

    const updateTotalProductCountManual = useCallback(
        (isAddedItemBenchtop = true, increment = true) => {
            setJob(
                produce(job, (draft) => {
                    draft.totalProductCount += increment ? 1 : -1;

                    if (!isAddedItemBenchtop) {
                        const room = draft.rooms.find(
                            (room) => room.id === roomId
                        );
                        if (room) {
                            room.productCount += increment ? 1 : -1;
                        }
                    }
                })
            );
        },
        [job, roomId]
    );

    return (
        <JobContext.Provider
            value={{
                job,
                setJob,
                refresh,
                room,
                setRoom,
                isPageDirty,
                setIsPageDirty,
                showDialog,
                hideDialog,
                isJobProcessing,
                actionButtonsEnabled,
                setLoader,
                expiredJobPrice,
                updateTotalProductCountManual,
            }}>
            <Loader loader={loader}>{children}</Loader>
            {dialog}
        </JobContext.Provider>
    );
};
