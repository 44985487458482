// @flow
import React, {useEffect} from 'react';
import {createContext} from 'react';
import {useContext} from 'react';
import {useState} from 'react';
import {useAppContext} from 'contexts';
import useUI from 'shared/useUI';

const DashboardContext: React$Context<any> = createContext();

export const useDashboardContext = (): React$Context<any> => {
    return useContext(DashboardContext);
};

type Props = {
    children?: React$Element<any>,
};

type Job = {
    id?: number,
};

type JobMenuInfo = {|
    'production-jobs'?: number,
    'quoted-jobs'?: number,
    'pending-confirmation-jobs'?: number,
    'pending-approval-jobs'?: number,
    'completed-jobs'?: number,
    'pending-payment-jobs'?: number,
|};

export const DashboardProvider = ({children}: Props): React$Element<any> => {
    const {setIsLeftDashboardButtonVisible, userProfile} = useAppContext();
    const {setIsCartToggleVisible} = useUI();

    const [lastJob, setLastJob] = useState<Job>({});
    const [isQuotedJobsVisibleMobile, setIsQuotedJobsVisibleMobile] =
        useState<boolean>(false);
    const [isPendingConfirmationJobsVisibleMobile, setIsPendingConfirmationJobsVisibleMobile] =
        useState<boolean>(false);
    const [isLeadTimesVisibleMobile, setIsLeadTimeVisibleMobile] =
        useState<boolean>(false);
    const [isProductionJobsVisibleMobile, setIsProductionJobsVisibleMobile] =
        useState<boolean>(false);
    const [isPendingJobsVisibleMobile, setIsPendingJobsVisibleMobile] =
        useState<boolean>(false);
    const [
        isPendingPaymentJobsVisibleMobile,
        setIsPendingPaymentJobsVisibleMobile,
    ] = useState<boolean>(false);
    const [isCompletedJobsVisibleMobile, setIsCompletedJobsVisibleMobile] =
        useState<boolean>(false);
    const [
        isUpdateCenterJobsVisibleMobile,
        setIsUpdateCenterJobsVisibleMobile,
    ] = useState<boolean>(true);

    const [jobInfo, setJobInfo] = useState<JobMenuInfo>({
        'pending-confirmation-jobs': 1,
        'production-jobs': 1,
        'quoted-jobs': 1,
        'pending-approval-jobs': 1,
        'completed-jobs': 1,
        'pending-payment-jobs': 1,
    });
    const [hasLeadTime, setHasLeadTime] = useState<boolean>(false);

    useEffect(() => {
        setIsLeftDashboardButtonVisible(false);
        setIsCartToggleVisible(false);
    }, []);

    return (
        <DashboardContext.Provider
            value={{
                lastJob,
                setLastJob,
                isQuotedJobsVisibleMobile,
                setIsQuotedJobsVisibleMobile,
                isPendingConfirmationJobsVisibleMobile,
                setIsPendingConfirmationJobsVisibleMobile,
                isLeadTimesVisibleMobile,
                setIsLeadTimeVisibleMobile,
                isProductionJobsVisibleMobile,
                setIsProductionJobsVisibleMobile,
                isPendingJobsVisibleMobile,
                setIsPendingJobsVisibleMobile,
                isPendingPaymentJobsVisibleMobile,
                setIsPendingPaymentJobsVisibleMobile,
                isCompletedJobsVisibleMobile,
                setIsCompletedJobsVisibleMobile,
                isUpdateCenterJobsVisibleMobile,
                setIsUpdateCenterJobsVisibleMobile,
                jobInfo,
                setJobInfo,
                hasLeadTime,
                setHasLeadTime,
                userProfile,
            }}>
            {children}
        </DashboardContext.Provider>
    );
};
